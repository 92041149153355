@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
}

.shadow-box {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.144);
}

body {
    font-family: 'Lexend', sans-serif;
    font-weight: 300;
}

.bg-pattern-2 {
    background-image: url('./assets/pattern 2.png');
    background-size: contain;
    background-position: center;
}

.bg-Ellipse-1 {
    background-image: url('./assets/Ellipse1.png');
    background-size: contain;
    background-position: center;
}

.bg-Ellipse-2 {
    background-image: url('./assets/Ellipse2.png');
    background-size: contain;
    background-position: center;
}

.bg-Ellipse-3 {
    background-image: url('./assets/Ellipse3.png');
    background-size: contain;
    background-position: center;
}

.Maskgroup-bg {
    background-image: url('./assets/MaskGroup4.svg');
    background-size: cover;
    background-position: center;
}

.newsletter-bg {
    background-image: url('./assets/Newsletter.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.white-image {
    filter: grayscale(100%) brightness(1000%);
}

.landing-bg {
    background-image: url('./assets/HomeBanner.png');
    background-size: cover;
    background-position: center;
}

.culture-bg {
    background-image: url('./assets/culture.png');
    background-size: cover;
    background-position: center;
}

.franchise-bg {
    background-image: url('https://i.pinimg.com/originals/83/87/dc/8387dceff44495a8dd13083cb6df128e.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    /* border-radius: 100%; */
    background-position: center;
}

.storelocator-bg {
    background-image: url('https://img.freepik.com/premium-vector/nature-mountain-forest-jungle-landscape-background-vector-flat-color_194708-736.jpg?w=1380');
    background-size: cover;
    background-repeat: no-repeat;
    /* border-radius: 100%; */
    background-position: center;
}

.shadow-t {
    box-shadow: 0px 0px 16px 3px rgb(0 0 0 / 15%);
}

body::-webkit-scrollbar {
    display: none;
}


shake:hover {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.flip-horizontally {
    transform: scaleX(-1);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}